
import { defineComponent, ref, onMounted, reactive, toRefs, onBeforeUnmount, watch } from 'vue'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import GC from '@grapecity/spread-sheets'
import { ssHotTop, usetableHeight } from './utils'
import blobDownload from '@/utils/blobDownload'
import {KufriPanelProps} from './types'
import { getKufriPanelData, findKufriPanelData, getKpiList } from '@/API/sysParam'
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue'
import KufriDataImport from './KufriDataImport.vue'
import { useStore } from 'vuex'
export default defineComponent({
    components: {
        KufriDataImport
    },
    name: 'KufriPanelControl',
    setup() {
        const router = useRouter() 
        // 获取表格的高度
        const {tableHeight} = usetableHeight('#ssHost', '#ssHostvp')

        const make = useStore().state.make;
        // c
        const visible = ref(false)
        // 获取路由的参数
        const route = useRoute()
        const { query } = useRoute();
        // 设置初始化数据变量
        const dataSource = reactive<KufriPanelProps>({
            version: [],
            data: [],
            year: [],
            kpi: []
        })
        // Object.assign(dataSource, data)
        const versionValue = ref()
        const yearValue = ref()
        const kpiValue = ref()
        // 初始化Spread
        // sheet当前表格的初始化
        let workbook: GC.Spread.Sheets.Workbook
        const ssHotTopInit = () => {
            const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            workbook = spread;
            ssHotTop(spread, dataSource.data)
        }

        // 获取数据
        const initKufriPanelData = () => {
            const params = {
                make
            }
            getKufriPanelData({params}).then(res => {
                Object.assign(dataSource, res)
                const versionArr = res.version.filter(item => item.display)
                versionValue.value = versionArr.length > 0 ? versionArr[0].version : ''
                yearValue.value = res.year[1]
                kpiValue.value = res.kpi[0]
                ssHotTopInit()
            })
        }

        // 搜索
        const handlerSearch = () => {
            const params = {
                make,
                version: versionValue.value,
                year: yearValue.value,
                kpi: kpiValue.value
            }
            findKufriPanelData({params}).then(res => {
                dataSource.data = res
                if (workbook) {
                    workbook.destroy();
                    ssHotTopInit()
                }
            })
        }

        // 导出表格
        const handlerExport = () => {
            if (versionValue.value && yearValue.value && kpiValue.value) {
                const config = {
                url: '/vapi/kufri/control/export',
                params: {
                        make,
                        version: versionValue.value,
                        year: yearValue.value,
                        kpi: kpiValue.value
                    }
                }
                blobDownload(config)
            } else {
                message.error("Please select parameters")
            }
        }

        // 上传成功 获取数据
        const hanlderSearchClick = () => {
            handlerSearch()
        } 
        const isDetail = ref(false)

        // 切换kpi 获取数据
        const changeKpi = () => {
            if (!isDetail.value) {
                return
            } else {
                 handlerSearch()
            }
        }

        // 跳转到Kufri Active页面
        const activeNewKufri = () => {               
            router.push({ name: 'KufriActive'});
        }
        watch(() => {
            return route.path
        }, (path ) => {
            if (path === '/kufriPanel/' + make) {
                window.location.reload()
            }
        })
        onMounted(() => {
            
            if (route.path === '/kufriDetail') {
                isDetail.value = true
                versionValue.value = query.version
                yearValue.value = query.year
                ssHotTopInit()
                getKpiList().then((res: string[]) => {
                    dataSource.kpi = res
                    kpiValue.value = res[0]
                    handlerSearch()
                })
            } else {
                
                isDetail.value = false
                initKufriPanelData()
            }
        })
       
        onBeforeUnmount(() => {
            workbook.destroy();
        })

        return {
            isDetail,
            // table的高度
            tableHeight,

            // 导出文件
            handlerExport,
            handlerSearch,
            activeNewKufri,

            ...toRefs(dataSource),

            versionValue,
            yearValue,
            kpiValue,

            visible,
            hanlderSearchClick,
            changeKpi,
        }

    }
 });
